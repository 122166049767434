import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box } from '@mui/material';

export default function Spørsmål() {
    return (
        <Box sx={{ maxWidth: 500 }}>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Hva har man på seg?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Kleskoden er mørk dress / Kjole og hvitt. Det er også mulig å bruke bunad. 
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Kan jeg parkere der?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Det går an å parkere over natten på veien utenfor lokalet. Lokalet er også i nærheten av Ris og Slemdal T-banestasjoner, så det er mulig å ta T-banen.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Blir det nattmat?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Det blir burger.
                </Typography>
                </AccordionDetails>
            </Accordion>
        
        </Box>
    );
}