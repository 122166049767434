import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';

export default function SectionNormal({ heading = "Our Story", text = "This is the default text.", image = "/logo192.png", includeButton = false, buttonText = "Read More", buttonLink = "#" }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, margin: 0, maxWidth: 600 }}>
        <Card sx={{backgroundColor: 'background.default', maxWidth: '600px'}}>
            <CardMedia
                component="img"
                alt="Hvor er bildet?"
                height="500"
                image={image}
            />
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', gap: 2, paddingBottom: 3 }}>
                    <h2>{heading}</h2>
                </Box>  
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                {text}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', gap: 2, paddingTop: 3 }}>
                    {includeButton && <Button variant="contained" href={buttonLink} sx={{height: '35px', borderRadius: '40px'}}>{buttonText}</Button>}
                </Box> 
            </CardContent>
        </Card>
    </Box>
    );
}