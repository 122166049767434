import SideMeny from './SideMeny';
import Countdown from './Countdown';
import { Typography, CardMedia, Box } from '@mui/material';

export default function Header() {
    const weddingDate = 'September 6, 2025 14:00:00';
    return (
        <section className="main-section">
            <SideMeny />
            <Box sx={{ height: '50vh', overflow: 'hidden' }}>
                <CardMedia 
                    component="img"
                    alt="Torjus & Victoria"
                    image="/assets/forside_v&t-min.png"
                    sx={{minHeight: '100%', minWidth: '100%', objectFit: 'cover', maxWidth: '1300px'}}
                />
            </Box>
            <Box>
                <Typography variant="h1" component="h1" sx={{ textAlign: 'center', fontFamily: 'Kaushan Script', fontSize: 'calc(40px + 6vmin)', paddingBottom: 3 }}>
                    Torjus & Victoria
                </Typography>
                <Typography variant="h4" component="h1" sx={{ textAlign: 'center', fontFamily: 'Kaushan Script', fontSize: 'calc(10px + 6vmin)' }}>
                    Vi gifter oss!
                </Typography>
                <Typography variant="h4" component="h1" sx={{ textAlign: 'center', fontFamily: 'Kaushan Script', fontSize: 'calc(9px + 3vmin)', paddingBottom: 3 }}>
                    6. September 2025
                </Typography>
                <Countdown targetDate={weddingDate} />
            </Box>
            <Box></Box>
        </section>
    );
}