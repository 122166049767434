import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CardMedia from '@mui/material/CardMedia';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function SideMeny() {
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        event.preventDefault(); // Prevent default anchor link behavior
        event.stopPropagation(); // Stop the click event from bubbling up
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleMenuItemClick = (sectionId) => (event) => {
        event.preventDefault(); // Prevent default anchor link behavior
        event.stopPropagation(); // Stop the click event from bubbling up
        const element = document.querySelector(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        // Close the drawer
        setState({ ...state, right: false });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem key="home" disablePadding sx={{paddingBottom: 4}}>
                    <ListItemButton onClick={handleMenuItemClick('#home')}>
                    <ListItemIcon>
                    <CardMedia
                        component="img"
                        alt="Hvor er bildet?"
                        height="200"
                        image="/logo192.png"
                        sx={{paddingLeft: 7}}
                    />
                    </ListItemIcon>
                    </ListItemButton>
                </ListItem>

                <ListItem key="program" disablePadding sx={{paddingBottom: 4}}>
                    <ListItemButton onClick={handleMenuItemClick('#program-section')}>
                    <ListItemIcon>
                        <ScheduleIcon />
                    </ListItemIcon>
                    <Typography variant='h6'>Program for dagen</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem key="qna" disablePadding sx={{paddingBottom: 4}}>
                    <ListItemButton onClick={handleMenuItemClick('#qna-section')}>
                    <ListItemIcon>
                        <QuestionMarkIcon />
                    </ListItemIcon>
                    <Typography variant='h6'>Spørsmål og Svar</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem key="rsvp" disablePadding sx={{paddingBottom: 4}}>
                    <ListItemButton onClick={handleMenuItemClick('#rsvp-section')}>
                    <ListItemIcon>
                        <MailIcon />
                    </ListItemIcon>
                    <Typography variant='h6'>RSVP</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem key="wishes" disablePadding sx={{paddingBottom: 4}}>
                    <ListItemButton onClick={handleMenuItemClick('#wishlist-section')}>
                    <ListItemIcon>
                        <VolunteerActivismIcon />
                    </ListItemIcon>
                    <Typography variant='h6'>Ønskeliste</Typography>
                    </ListItemButton>
                </ListItem>
                
            </List>
        </Box>
    );

    return (
        <div className='burger'>
            <React.Fragment key={"right"}>
                <IconButton edge="start" color="night" aria-label="menu" onClick={toggleDrawer("right", true)} sx={{ position: 'fixed', top: '2rem', right: '2rem', zIndex: 1300 }}>
                    <MenuIcon sx={{scale: 2, color: 'black'}}/>
                </IconButton>
                <Drawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                >
                    {list("right")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
