import React, { useState } from 'react';
import CustomizedTimeline from './Timeline.js';
import Header from './Header.js';
import SectionNormal from './SectionNormal.js';
// import { Typography } from '@mui/material';
import Rsvp from './Rsvp.js';
import Wishlist from './Wishlist.js';
import Footer from './Footer.js';
import Spørsmål from './Spørsmål.js';
import PlaceIcon from '@mui/icons-material/Place';
import { Button } from '@mui/material';

export default function Root() {
    const [displayWishlist, setDisplayWishlist] = useState(false);

    return (
        <div className="App" id='home'>
            <Header />

            <section className="section"> 
                <SectionNormal heading="Det skjedde i de dager..." text="Torjus and Victoria met at a mutual friend's birthday party in 2018. They instantly connected over their shared love for adventure and trying new things. From that moment on, they became inseparable and embarked on many exciting journeys together. After three years of dating, Torjus proposed to Victoria during a romantic getaway to the mountains. Victoria, of course, said yes, and they began planning their dream wedding." image="/assets/ekstramedring-min.png" />
            </section>

            <section className="section"> 
                <SectionNormal heading="Lokalet - Villa Eckbo" text="Dere inviteres til å være med på vielse, middag og fest på Villa Eckbo. Vi gleder oss til å samle både venner og famile på begge sider for denne anledningen. Det skal giftes, spises, drikkes og mingles, tales og danses." image="https://www.eventbyraaet.no/wp-content/uploads/2017/11/Villa-Eckbo-selskapslokaler.jpg" includeButton={true} buttonText={["Veien til lokalet", <PlaceIcon />]} buttonLink="https://maps.app.goo.gl/vxPEDihBWStMmpbC9" />
            </section>
            
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '100vw',
                    overflow: 'hidden'
                }}
            >
                <img
                    src="/assets/banner_v&t-min.png"
                    alt="bilde"
                    style={{
                        width: '100%',
                        minHeight: '420px',
                        objectFit: 'cover',
                        objectPosition: '35% center',
                    }}
                />
            </div>

            
            <section className="section" id='program-section'> 
                <h2>Program for dagen</h2>
                <CustomizedTimeline />
            </section>


            <div style={{display: 'flex', justifyContent: 'center', maxWidth:'100vw', overflowX: 'clip'}}>

                <img src="/assets/ekstra2_v&t-min.png" alt="bilde" style={{maxWidth: '250px', height: 'auto', borderRadius: '50%', transform: 'translateX(140px) translateY(100px) rotate(-13deg)', zIndex: '1'}} />
                
                <img src="/assets/ekstra3_v&t-min.png" alt="bilde" style={{maxWidth: '250px', height: 'auto', borderRadius: '50%', transform: 'translateX(0px) translateY(-80px) rotate(0deg)', zIndex: '3'}} />

                <img src="/assets/ekstra4_v&t-min.png" alt="bilde" style={{maxWidth: '250px', height: 'auto', borderRadius: '50%', transform: 'translateX(-140px) translateY(120px) rotate(13deg)', zIndex: '2'}} />

            </div>

            <section className="section" id='qna-section'>
                <h2>Spørsmål & Svar</h2>
                <Spørsmål />
            </section>

           

            <section className="section" id='rsvp-section'>
                <h2>RSVP</h2>
                <Rsvp />
            </section>


            <section className="section" id='wishlist-section'>
                {displayWishlist ? <h2>Ønskeliste</h2> : <h2>Har du lyst til å se hva vi ønsker oss?</h2>}
                {!displayWishlist && <Button variant="contained" onClick={() => setDisplayWishlist(true)}>Se ønskeliste</Button>}
                {displayWishlist && <Wishlist />}
            </section>


            <Footer />
        </div>
    );
}
